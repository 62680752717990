import { useEffect, useState, useContext, useMemo } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {
  Container,
  Box,
  Stack,
  Modal,
  Typography,
  TextField,
  Button,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { Like } from './like'
import _ from 'lodash'
import $axios from '../../tools/axiosWrapper'
import BookmarkIcon from '@mui/icons-material/Bookmark'

export const Likes = ({ user }) => {
  const theme = useTheme()
  const [likes, setLikes] = useState([])
  const [load, setLoad] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    setLoad(true)
    $axios
      .get('/collection/list/' + 'bookmark')
      .then((response) => {
        console.log('bookmark', response.data)
        setLikes(response.data.documents)
        setLoad(false)
      })
      .catch((error) => {
        setLoad(false)
      })
  }, [])

  return (
    <Stack
      style={{
        width: '100vw',
        minHeight: '100vh',
      }}
      alignItems={'center'}
      sx={{ pr: 8 }}
    >
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ mt: 4 }}
      >
        <BookmarkIcon sx={{ transform: 'scale(1.5)' }} />
        <Typography variant={'h4'}>saved documents</Typography>
      </Stack>

      <Stack
        sx={{
          mt: 10,
          px: 4,
          py: 8,
          width: '80vw',
          height: '80vh',
          maxWidth: 1000,
          // background: theme.palette.purple[0],
          borderRadius: '10px',
          overflowY: 'scroll',
        }}
        spacing={8}
      >
        {likes.filter((d) => d.includes('odoc')).length == 0 && (
          <Stack sx={{ width: '100%' }} alignItems='center'>
            <Typography>no saved documents</Typography>
          </Stack>
        )}
        {likes
          .filter((d) => d.includes('odoc'))
          .map((d, i) => (
            <Like key={d + i} d={d} i={i} />
          ))}
      </Stack>
    </Stack>
  )
}
