import { useEffect, useState, useContext, useMemo } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {
    Container,
    Box,
    Stack,
    Modal,
    Typography,
    TextField,
    Button,
    LinearProgress,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { Like } from './like'
import { TagItem } from './tagitem'
import _ from 'lodash'
import $axios from '../../tools/axiosWrapper'


const Images = ({ images }) => {
    let elements = images.map(i => {
        return (
            <>
                <img key={i.imageid} src={i.imgurl} width={'50%'} height='auto' />
            </>
        )
    });
    return elements;
};


const INIT = { images: [], documentid: '' };
const StaticDoc = ({ user }) => {
    const theme = useTheme();
    const [doc, setDoc] = useState(INIT)

    const [load, setLoad] = useState(true);

    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        document.documentElement.scrollTo(0, 0)
    }, [])

    // useEffect(() => {
    //     setLoad(true)

    //     $axios
    //         .get(
    //             '/doc/all/' +
    //             location.pathname
    //                 .replace('/user/doc/', '')
    //         )
    //         .then((response) => {
    //             setDoc(response.data);
    //             console.log(response.data.images);
    //         })
    //         .catch((er) => console.log(er))
    //         .finally(() => setLoad(false))
    // }, [])


    return (
        <>
            {/* {load ? (
                <LinearProgress />
            ) : (
                <Box sx={{ height: '4px', width: '100vw' }}></Box>
            )} */}
            
            {/* {(user && !load) && <Images images={doc.images} />} */}

            
            <Stack
                style={{
                    width: '100vw',
                    minHeight: '100vh',
                }}
                alignItems={'center'}
                sx={{ pr: 8 }}
            >
                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ mt: 4 }}
                >
                </Stack>

                <Stack
                    sx={{
                        mt: 10,
                        px: 4,
                        py: 8,
                        width: '80vw',
                        maxWidth: 1000,
                        // background: theme.palette.purple[0],
                        borderRadius: '10px',
                        overflowY: 'scroll',
                    }}
                    spacing={8}
                >
                    <Stack sx={{ width: '100%' }} alignItems='center' textAlign='center'>
                        <Typography marginBottom={2}>
                            Thank you for your interest in FBarchive. 
                            In order to view this image, please log in by clicking the button below.
                        </Typography>
                        <Button
                            variant={'contained'}
                            size={'large'}
                            onClick={() => {
                                $axios.get('/auth/login').then((response) => {
                                    window.location.href = response.data.login_url
                                })
                            }}
                        >
                            log in
                        </Button>
                    </Stack>
                    
                    <Stack sx={{ width: '100%' }} alignItems='center' textAlign='center'>
                        <Typography marginBottom={2}>
                            Don’t have an account? Sign up now by clicking the below button.
                        </Typography>
                        <Button
                            variant={'contained'}
                            size={'large'}
                            onClick={() => {
                                $axios.get('/auth/login').then((response) => {
                                    window.location.href = response.data.login_url.replace("login", "signup");
                                })
                            }}
                        >
                            sign up
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
};

export default StaticDoc;